import React, { ReactNode, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getToken } from "../apiService/apiService";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { getUserLimitedInfo } from "../apiService/apiService";
import Loader from "../components/Loader/Loader";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const [validSubscription, setValidSubscription] = useState<boolean | null>(
    null,
  );
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const token = getToken();
  const location = useLocation(); // To get the current location (path)

  // If the current page is /pricing, we don't need to check the subscription status
  const isPricingPage = location.pathname === "/pricing";

  useEffect(() => {
    if (!token) {
      setIsLoading(false); // No token, so stop loading
      return;
    }

    if (isPricingPage) {
      setIsLoading(false); // No need to check subscription on /pricing page
      return;
    }

    try {
      const decodedToken = jwtDecode<JwtPayload>(token);

      if (decodedToken.exp) {
        const expirationTime = decodedToken.exp;
        const currentTime = Math.floor(Date.now() / 1000);

        if (currentTime >= expirationTime) {
          setIsTokenExpired(true);
          setIsLoading(false); // Stop loading after checking token expiration
          return;
        }
      } else {
        setIsTokenExpired(true);
        setIsLoading(false); // Stop loading if no expiration
        return;
      }

      // If the token is valid, fetch the user info and check subscription validity
      getUserLimitedInfo()
        .then((data) => {
          setValidSubscription(data.validSubscription);
          setIsLoading(false); // Stop loading after fetching subscription data
        })
        .catch(() => {
          setValidSubscription(false);
          setIsLoading(false); // Stop loading if there's an error fetching subscription data
        });
    } catch {
      setValidSubscription(false);
      setIsLoading(false); // Stop loading if there's an error decoding token
    }
  }, [token, isPricingPage]);

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (isTokenExpired) {
    return <Navigate to="/login" />;
  }

  // Only redirect to /pricing if the user is not on the /pricing page and has an invalid subscription
  if (validSubscription === false && !isPricingPage) {
    return <Navigate to="/pricing" />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
