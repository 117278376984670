import React from "react";
import { Link } from "react-router-dom";
import "./ManagerizeWorkforceOnboarding.css";

const ManagerizeWorkforceOnboarding = () => {
  return (
    <div className="managerize-workforce-onboarding">
      <title>Managerize: Workforce - How to use</title>
      <div className="banner">
        <img
          src="/images/header/banner.png"
          alt="Banner"
          className="banner-image"
        />
      </div>

      <div className="managerize-workforce-onboarding-content">
        <Link to="/">
          <img src="/images/logo/logo.svg" alt="Logo" className="logo" />
        </Link>

        <div className="managerize-workforce-onboarding-container">
          <header>Managerize: Workforce - How to use</header>
          <section>
            <strong>Register</strong>
            <p>1. Download the Managerize: Workforce app</p>
            <li>
              iOS:{" "}
              <a
                href="https://apps.apple.com/us/app/managerize-workforce/id6741440438"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://apps.apple.com/us/app/managerize-workforce/id6741440438
              </a>
            </li>
            <li>
              Android:{" "}
              <a
                href="https://play.google.com/store/apps/details?id=com.managerize&pcampaignid=web_share"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://play.google.com/store/apps/details?id=com.managerize&pcampaignid=web_share
              </a>
            </li>
            <p>
              2. Start the app and allow notifications so you don&apos;t miss
              assignments and chat messages from your employer
            </p>
            <p>
              3. Enter the ID you have recieved from your employer. It should be
              an 8 character ID (eg. 1FRqHUnN)
            </p>
            <img
              src="/images/managerize-workforce-onboarding/input-employee-id.png"
              alt="Input Employee ID"
              className="phone-screenshot"
            />
            <p>
              4. Enter a PIN that you will use to log into the future. If
              forgotten, please contact your employer
            </p>
            <img
              src="/images/managerize-workforce-onboarding/register.png"
              alt="Register"
              className="phone-screenshot"
            />
            <p>5. Log in</p>
            <img
              src="/images/managerize-workforce-onboarding/login.png"
              alt="Log in"
              className="phone-screenshot"
            />
          </section>

          <section>
            <strong>Home Page</strong>
            <p>
              1. On the home page, you will have the &quot;Clock in&quot;,
              &quot;Calendar&quot;, &quot;Chat&quot; and &quot;Earnings&quot;
              buttons
            </p>
            <img
              src="/images/managerize-workforce-onboarding/home.png"
              alt="Home Page"
              className="phone-screenshot"
            />
          </section>

          <section>
            <strong>Clock in</strong>
            <p>
              1. When you are ready to start working, click on the &quot;Clock
              In&quot; button. This will signal your employer that you have
              started working
            </p>
            <p>
              2. When you are finished working, provide a signature and click on
              the &quot;Clock Out&quot; button. This will signal your employer
              that you have finished working
            </p>
            <img
              src="/images/managerize-workforce-onboarding/clockout.png"
              alt="Clock Out"
              className="phone-screenshot"
            />
          </section>

          <section>
            <strong>Calendar</strong>
            <p>
              1. Here, you will have an overview of the gig assignments you have
              been assigned to. All the details for the gigs will be provided
              here
            </p>
            <img
              src="/images/managerize-workforce-onboarding/gigs.png"
              alt="Gigs"
              className="phone-screenshot"
            />
          </section>

          <section>
            <strong>Chat</strong>
            <p>1. Here, you can chat directly with your employer</p>
            <img
              src="/images/managerize-workforce-onboarding/chat.png"
              alt="Chat"
              className="phone-screenshot"
            />
          </section>

          <section>
            <strong>Earnings</strong>
            <p>
              1. Here, you can view all the work you have done, alongside your
              earnings
            </p>
            <img
              src="/images/managerize-workforce-onboarding/earnings.png"
              alt="Chat"
              className="phone-screenshot"
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default ManagerizeWorkforceOnboarding;
