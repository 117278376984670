import React from "react";
import { Link } from "react-router-dom";
import "./Onboarding.css";

const Onboarding = () => {
  return (
    <div className="onboarding">
      <title>Onboarding</title>
      <div className="banner">
        <img
          src="/images/header/banner.png"
          alt="Banner"
          className="banner-image"
        />
      </div>

      <div className="onboarding-content">
        <Link to="/dashboard">
          <img src="/images/logo/logo.svg" alt="Logo" className="logo" />
        </Link>

        <div className="onboarding-container">
          <header>Onboarding</header>
          <section>
            <strong>How to add an employee</strong>
            <p>
              1. Select the &quot;Add Employee&quot; button in the Employee List
              section on the main Dashboard
            </p>
            <p>2. Enter employee details, then click &quot;Submit&quot;</p>
            <p>3. Send the employee their automatically generated ID</p>
            <p>4. Send the employee the Managerize: Workforce app</p>
            <li>
              iOS:{" "}
              <a
                href="https://apps.apple.com/us/app/managerize-workforce/id6741440438"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://apps.apple.com/us/app/managerize-workforce/id6741440438
              </a>
            </li>
            <li>
              Android:{" "}
              <a
                href="https://play.google.com/store/apps/details?id=com.managerize&pcampaignid=web_share"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://play.google.com/store/apps/details?id=com.managerize&pcampaignid=web_share
              </a>
            </li>
            <p>
              5. Send the employee the Managerize: Workforce app onboarding
              document
            </p>
            <li>
              Managerize: Workforce - How to use:{" "}
              <a
                href="https://managerize.com/managerize-workforce-onboarding"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://managerize.com/managerize-workforce-onboarding
              </a>
            </li>
          </section>
          <br></br>
          <section>
            <strong>How to add and assign gigs</strong>
            <p>
              1. Select the &quot;Gigs&quot; icon on the main Dashboard
              navigation, or go to &quot;Manage Gigs&quot;
            </p>
            <p>2. Click on &quot;Add Gig&quot;</p>
            <p>3. Enter the gig information and click &quot;Save&quot;</p>
            <p>
              4. Navigate on the calendar to the needed gig&apos;s date and
              click the &quot;Select Employee&quot; dropdown menu. Now, select
              the employee you want to assign to the gig
            </p>
          </section>
          <br></br>
          <section>
            <strong>Chat with employees</strong>
            <p>1. On the main Dashboard, locate &quot;Employee Chats&quot;</p>
            <p>2. Click on the employee you wish to chat with</p>
            <p>3. Write your message, then click &quot;Send&quot;</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
