import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUpcomingGigs } from "../../apiService/apiService";
import Loader from "../Loader/Loader";
import "./OverviewGigs.css";
import { Gig } from "../../constants/interfaces";

const formatDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const isFutureDate = (dateString: string): boolean => {
  return new Date(dateString) > new Date();
};

const isToday = (dateString: string): boolean => {
  const today = new Date();
  const eventDate = new Date(dateString);
  return (
    today.getDate() === eventDate.getDate() &&
    today.getMonth() === eventDate.getMonth() &&
    today.getFullYear() === eventDate.getFullYear()
  );
};

const sortGigsByDate = (gigs: Gig[]): Gig[] => {
  return gigs.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );
};

const OverviewGigs = () => {
  const navigate = useNavigate();
  const [upcomingGigs, setUpcomingGigs] = useState<Gig[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUpcomingGigs = async () => {
      setLoading(true);
      try {
        const gigs = await getUpcomingGigs();
        const sortedGigs = sortGigsByDate(gigs);
        setUpcomingGigs(sortedGigs);
      } catch (error) {
        console.error("Failed to fetch gigs:", error);
        if (error instanceof Error && error.message.includes("404")) {
          setError("No upcoming gigs found");
        } else {
          setError("Failed to fetch gigs. Please try again later.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUpcomingGigs();
  }, []);

  const handleRedirectToGigs = () => {
    navigate("/gigs");
  };

  return (
    <div className="upcoming-gigs">
      <div className="gigs-card">
        <div className="gigs-header">
          <h2>Upcoming Gigs</h2>
          <button
            className="navigate-gig-button"
            onClick={handleRedirectToGigs}
          >
            Manage Gigs
          </button>
        </div>
        <div className="status-message">
          {loading ? (
            <Loader isLoading={loading} />
          ) : error ? (
            <div className="error-message">{error}</div>
          ) : (
            <ul className="gig-list">
              {upcomingGigs.map((gig) => (
                <li key={gig.gigId} className="gig-item">
                  <div className="gig-details">
                    <strong>{gig.title}</strong>
                    <span
                      className={`gig-date ${
                        isToday(gig.date)
                          ? "today"
                          : isFutureDate(gig.date)
                            ? "future"
                            : "past"
                      }`}
                    >
                      {formatDate(gig.date)}
                    </span>
                  </div>
                  <span
                    className={`gig-status ${gig.status.trim().toLowerCase().replace(/\s+/g, "-")}`}
                  >
                    Status: {gig.status}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default OverviewGigs;
