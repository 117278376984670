import React from "react";
import { Link } from "react-router-dom";
import "./SCC.css";

const SCC = () => {
  return (
    <div className="scc-page">
      <title>Standard Contractual Clauses</title>
      <div className="banner">
        <img
          src="/images/header/banner.png"
          alt="Banner"
          className="banner-image"
        />
      </div>

      <div className="scc-page-content">
        <Link to="/">
          <img src="/images/logo/logo.svg" alt="Logo" className="logo" />
        </Link>

        <div className="scc-container">
          <header>EEA Standard Contractual Clauses</header>
          <section>
            <p>
              <strong>Effective: December 26, 2024</strong>
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>

            <p>Standard Contractual Clauses (processors)</p>

            <p>
              Data Controller as defined in the Data Processing Agreement (the
              &quot;<strong>data exporter</strong>&quot;),
            </p>

            <p>And</p>

            <p>
              Managerize, which is based in the United States, if the data
              exporter is transferring personal data to Managerize under the
              Data Processing Agreement;
            </p>

            <p>
              (the relevant Managerize entity described above is referred to as
              the &quot;<strong>data importer</strong>&quot;),
            </p>

            <p>each a &quot;party&quot;; together &quot;the parties&quot;,</p>

            <p>
              HAVE AGREED on the following Contractual Clauses (the
              &quot;Clauses&quot;) in order to adduce adequate safeguards with
              respect to the protection of privacy and fundamental rights and
              freedoms of individuals for the transfer by the data exporter to
              the data importer of the personal data specified in Appendix 1.
            </p>

            <h2>
              <strong>SECTION I</strong>
            </h2>
            <h2>
              <strong>
                <em>Clause 1</em>
              </strong>
            </h2>
            <h2>
              <strong>Purpose and scope</strong>
            </h2>
            <p>
              (a) The purpose of these standard contractual clauses is to ensure
              compliance with the requirements of Regulation (EU) 2016/679 of
              the European Parliament and of the Council of 27 April 2016 on the
              protection of natural persons with regard to the processing of
              personal data and on the free movement of such data (General Data
              Protection Regulation) (1) for the transfer of personal data to a
              third country.
            </p>
            <p>(b) The Parties:</p>
            <p>
              (i) the natural or legal person(s), public authority/ies,
              agency/ies or other body/ies (hereinafter &apos;entity/ies&apos;)
              transferring the personal data, as listed in Annex I. A
              (hereinafter each &apos;data exporter&apos;), and
            </p>
            <p>
              (ii) the entity/ies in a third country receiving the personal data
              from the data exporter, directly or indirectly via another entity
              also Party to these Clauses, as listed in Annex I. A (hereinafter
              each &apos;data importer&apos;)
            </p>
            <p>
              have agreed to these standard contractual clauses (hereinafter:
              &apos;Clauses&apos;).
            </p>
            <p>
              (c) These Clauses apply with respect to the transfer of personal
              data as specified in Annex I.B.
            </p>
            <p>
              (d) The Appendix to these Clauses containing the Annexes referred
              to therein forms an integral part of these Clauses.
            </p>
            <h2>
              <strong>
                <em>Clause 2</em>
              </strong>
            </h2>
            <h2>
              <strong>Effect and invariability of the Clauses</strong>
            </h2>
            <p>
              (a) These Clauses set out appropriate safeguards, including
              enforceable data subject rights and effective legal remedies,
              pursuant to Article 46(1) and Article 46(2)(c) of Regulation (EU)
              2016/679 and, with respect to data transfers from controllers to
              processors and/or processors to processors, standard contractual
              clauses pursuant to Article 28(7) of Regulation (EU) 2016/679,
              provided they are not modified, except to select the appropriate
              Module(s) or to add or update information in the Appendix. This
              does not prevent the Parties from including the standard
              contractual clauses laid down in these Clauses in a wider contract
              and/or adding other clauses or additional safeguards, provided
              that they do not contradict, directly or indirectly, these Clauses
              or prejudice the fundamental rights or freedoms of data subjects.
            </p>
            <p>
              (b) These Clauses are without prejudice to obligations to which
              the data exporter is subject by virtue of Regulation (EU)
              2016/679.
            </p>

            <h2>
              <strong>
                <em>Clause 3</em>
              </strong>
            </h2>
            <h2>
              <strong>Third-party beneficiaries</strong>
            </h2>
            <p>
              (a) Data subjects may invoke and enforce these Clauses, as
              third-party beneficiaries, against the data exporter and/or data
              importer, with the following exceptions:
            </p>
            <p>(i) Clause 1, Clause 2, Clause 3, Clause 6, Clause 7;</p>
            <p>(ii) Clause 8.1(b), 8.9(a), (c), (d) and (e);</p>
            <p>(iii) Clause 9 &ndash; Clause 9(a), (c), (d) and (e);</p>
            <p>(iv) Clause 12 &ndash; Clause 12(a), (d) and (f);</p>
            <p>(v) Clause 13;</p>
            <p>(vi) Clause 15.1(c), (d) and (e);</p>
            <p>(vii) Clause 16(e);</p>
            <p>(viii) Clause 18 &ndash; Clause 18(a) and (b);</p>
            <p>
              (b) Paragraph (a) is without prejudice to the rights of data
              subjects under Regulation (EU) 2016/679.
            </p>
            <h2>
              <strong>
                <em>Clause 4</em>
              </strong>
            </h2>
            <h2>
              <strong>Interpretation</strong>
            </h2>
            <p>
              (a) Where these Clauses use terms that are defined in Regulation
              (EU) 2016/679, those terms shall have the same meaning as in that
              Regulation.
            </p>
            <p>
              (b) These Clauses shall be read and interpreted in the light of
              the provisions of Regulation (EU) 2016/679.
            </p>
            <p>
              (c) These Clauses shall not be interpreted in a way that conflicts
              with rights and obligations provided for in Regulation (EU)
              2016/679.
            </p>

            <h2>
              <strong>
                <em>Clause 5</em>
              </strong>
            </h2>
            <h2>
              <strong>Hierarchy</strong>
            </h2>
            <p>
              In the event of a contradiction between these Clauses and the
              provisions of related agreements between the Parties, existing at
              the time these Clauses are agreed or entered into thereafter,
              these Clauses shall prevail.
            </p>

            <h2>
              <strong>
                <em>Clause 6</em>
              </strong>
            </h2>
            <h2>
              <strong>Description of the transfer(s)</strong>
            </h2>
            <p>
              The details of the transfer(s), and in particular the categories
              of personal data that are transferred and the purpose(s) for which
              they are transferred, are specified in Annex I.B.
            </p>

            <h2>
              <strong>
                <em>Clause 7</em>
              </strong>
            </h2>
            <h2>
              <strong>Docking clause </strong>
            </h2>
            <p>
              (a) An entity that is not a Party to these Clauses may, with the
              agreement of the Parties, accede to these Clauses at any time,
              either as a data exporter or as a data importer, by completing the
              Appendix and signing Annex I.A.
            </p>
            <p>
              (b) Once it has completed the Appendix and signed Annex I.A, the
              acceding entity shall become a Party to these Clauses and have the
              rights and obligations of a data exporter or data importer in
              accordance with its designation in Annex I.A.
            </p>
            <p>
              (c) The acceding entity shall have no rights or obligations
              arising under these Clauses from the period prior to becoming a
              Party.
            </p>
            <h2>
              <strong>SECTION II &ndash; OBLIGATIONS OF THE PARTIES</strong>
            </h2>
            <h2>
              <strong>
                <em>Clause 8</em>
              </strong>
            </h2>
            <h2>
              <strong>Data protection safeguards</strong>
            </h2>
            <p>
              The data exporter warrants that it has used reasonable efforts to
              determine that the data importer is able, through the
              implementation of appropriate technical and organisational
              measures, to satisfy its obligations under these Clauses.
            </p>
            <h2>
              <strong>8.1 Instructions</strong>
            </h2>
            <p>
              (a) The data importer shall process the personal data only on
              documented instructions from the data exporter. The data exporter
              may give such instructions throughout the duration of the
              contract.
            </p>
            <p>
              (b) The data importer shall immediately inform the data exporter
              if it is unable to follow those instructions.
            </p>
            <h2>
              <strong>8.2 Purpose limitation</strong>
            </h2>
            <p>
              The data importer shall process the personal data only for the
              specific purpose(s) of the transfer, as set out in Annex I.B,
              unless on further instructions from the data exporter.
            </p>
            <h2>
              <strong>8.3 Transparency</strong>
            </h2>
            <p>
              On request, the data exporter shall make a copy of these Clauses,
              including the Appendix as completed by the Parties, available to
              the data subject free of charge. To the extent necessary to
              protect business secrets or other confidential information,
              including the measures described in Annex II and personal data,
              the data exporter may redact part of the text of the Appendix to
              these Clauses prior to sharing a copy but shall provide a
              meaningful summary where the data subject would otherwise not be
              able to understand its content or exercise his/her rights. On
              request, the Parties shall provide the data subject with the
              reasons for the redactions, to the extent possible without
              revealing the redacted information. This Clause is without
              prejudice to the obligations of the data exporter under Articles
              13 and 14 of Regulation (EU) 2016/679.
            </p>
            <h2>
              <strong>8.4 Accuracy</strong>
            </h2>
            <p>
              If the data importer becomes aware that the personal data it has
              received is inaccurate, or has become outdated, it shall inform
              the data exporter without undue delay. In this case, the data
              importer shall cooperate with the data exporter to erase or
              rectify the data.
            </p>
            <h2>
              <strong>
                8.5 Duration of processing and erasure or return of data
              </strong>
            </h2>
            <p>
              Processing by the data importer shall only take place for the
              duration specified in Annex I.B. After the end of the provision of
              the processing services, the data importer shall, at the choice of
              the data exporter, delete all personal data processed on behalf of
              the data exporter and certify to the data exporter that it has
              done so, or return to the data exporter all personal data
              processed on its behalf and delete existing copies. Until the data
              is deleted or returned, the data importer shall continue to ensure
              compliance with these Clauses. In case of local laws applicable to
              the data importer that prohibit return or deletion of the personal
              data, the data importer warrants that it will continue to ensure
              compliance with these Clauses and will only process it to the
              extent and for as long as required under that local law. This is
              without prejudice to Clause 14, in particular, the requirement for
              the data importer under Clause 14(e) to notify the data exporter
              throughout the duration of the contract if it has reason to
              believe that it is or has become subject to laws or practices not
              in line with the requirements under Clause 14(a).
            </p>
            <h2>
              <strong>8.6 Security of processing</strong>
            </h2>
            <p>
              (a) The data importer and, during transmission, also the data
              exporter shall implement appropriate technical and organisational
              measures to ensure the security of the data, including protection
              against a breach of security leading to accidental or unlawful
              destruction, loss, alteration, unauthorised disclosure or access
              to that data (hereinafter &apos;personal data breach&apos;). In
              assessing the appropriate level of security, the Parties shall
              take due account the state of the art, the costs of
              implementation, the nature, scope, context and purpose(s) of
              processing and the risks involved in the processing for the data
              subjects. The Parties shall in particular consider having recourse
              to encryption or pseudonymisation, including during transmission,
              where the purpose of processing can be fulfilled in that manner.
              In the case of pseudonymisation, the additional information for
              attributing the personal data to a specific data subject shall,
              where possible, remain under the exclusive control of the data
              exporter. In complying with its obligations under this paragraph,
              the data importer shall at least implement the technical and
              organisational measures specified in Annex II. The data importer
              shall carry out regular checks to ensure that these measures
              continue to provide an appropriate level of security.
            </p>
            <p>
              (b) The data importer shall grant access to the personal data to
              members of its personnel only to the extent strictly necessary for
              the implementation, management and monitoring of the contract. It
              shall ensure that persons authorised to process the personal data
              have committed themselves to confidentiality or are under an
              appropriate statutory obligation of confidentiality.
            </p>
            <p>
              (c) In the event of a personal data breach concerning personal
              data processed by the data importer under these Clauses, the data
              importer shall take appropriate measures to address the breach,
              including measures to mitigate its adverse effects. The data
              importer shall also notify the data exporter without undue delay
              after having become aware of the breach. Such notification shall
              contain the details of a contact point where more information can
              be obtained, a description of the nature of the breach (including,
              where possible, categories and an approximate number of data
              subjects and personal data records concerned), its likely
              consequences and the measures taken or proposed to address the
              breach including, where appropriate, measures to mitigate its
              possible adverse effects. Where, and in so far as, it is not
              possible to provide all information at the same time, the initial
              notification shall contain the information then available and
              further information shall, as it becomes available, subsequently
              be provided without undue delay.
            </p>
            <p>
              (d) The data importer shall cooperate with and assist the data
              exporter to enable the data exporter to comply with its
              obligations under Regulation (EU) 2016/679, in particular, to
              notify the competent supervisory authority and the affected data
              subjects, taking into account the nature of processing and the
              information available to the data importer.
            </p>
            <h2>
              <strong>8.7 Sensitive data</strong>
            </h2>
            <p>
              Where the transfer involves personal data revealing racial or
              ethnic origin, political opinions, religious or philosophical
              beliefs, trade union membership, genetic data, or biometric data
              for the purpose of uniquely identifying a natural person, data
              concerning health or a person&apos;s sex life or sexual
              orientation, or data relating to criminal convictions and offences
              (hereinafter &apos;sensitive data), the data importer shall apply
              the specific restrictions and/or additional safeguards described
              in Annex I.B.
            </p>
            <h2>
              <strong>8.8 Onward transfers</strong>
            </h2>
            <p>
              The data importer shall only disclose the personal data to a third
              party on documented instructions from the data exporter. In
              addition, the data may only be disclosed to a third party located
              outside the European Union (4) (in the same country as the data
              importer or in another third country, hereinafter &apos;onward
              transfer&apos;) if the third party is or agrees to be bound by
              these Clauses, under the appropriate Module, or if:
            </p>
            <p>
              (a) the onward transfer is to a country benefiting from an
              adequacy decision pursuant to Article 45 of Regulation (EU)
              2016/679 that covers the onward transfer;
            </p>
            <p>
              (b) the third party otherwise ensures appropriate safeguards
              pursuant to Articles 46 or 47 Regulation of (EU) 2016/679 with
              respect to the processing in question;
            </p>
            <p>
              (c) the onward transfer is necessary for the establishment,
              exercise or defence of legal claims in the context of specific
              administrative, regulatory or judicial proceedings; or
            </p>
            <p>
              (d) the onward transfer is necessary in order to protect the vital
              interests of the data subject or of another natural person.
            </p>
            <p>
              Any onward transfer is subject to compliance by the data importer
              with all the other safeguards under these Clauses, in particular
              purpose limitation.
            </p>
            <h2>
              <strong>8.9 Documentation and compliance</strong>
            </h2>
            <p>
              (a) The data importer shall promptly and adequately deal with
              enquiries from the data exporter that relate to the processing
              under these Clauses.
            </p>
            <p>
              (b) The Parties shall be able to demonstrate compliance with these
              Clauses. In particular, the data importer shall keep appropriate
              documentation on the processing activities carried out on behalf
              of the data exporter.
            </p>
            <p>
              (c) The data importer shall make available to the data exporter
              all information necessary to demonstrate compliance with the
              obligations set out in these Clauses and at the data
              exporter&apos;s request, allow for and contribute to audits of the
              processing activities covered by these Clauses, at reasonable
              intervals or if there are indications of non-compliance. In
              deciding on a review or audit, the data exporter may take into
              account relevant certifications held by the data importer.
            </p>
            <p>
              (d) The data exporter may choose to conduct the audit by itself or
              mandate an independent auditor. Audits may include inspections at
              the premises or physical facilities of the data importer and
              shall, where appropriate, be carried out with reasonable notice.
            </p>
            <p>
              (e) The Parties shall make the information referred to in
              paragraphs (b) and (c), including the results of any audits,
              available to the competent supervisory authority on request.
            </p>
            <h2>
              <strong>
                <em>Clause 9</em>
              </strong>
            </h2>
            <h2>
              <strong>Use of sub-processors </strong>
            </h2>
            <p>
              (a) The data importer has the data exporter&apos;s general
              authorisation for the engagement of sub-processor(s) from an
              agreed list. The data importer shall specifically inform the data
              exporter in writing of any intended changes to that list through
              the addition or replacement of sub-processors at least fourteen
              days in advance, thereby giving the data exporter sufficient time
              to be able to object to such changes prior to the engagement of
              the sub-processor(s). The data importer shall provide the data
              exporter with the information necessary to enable the data
              exporter to exercise its right to object.
            </p>
            <p>
              (b) Where the data importer engages a sub-processor to carry out
              specific processing activities (on behalf of the data exporter),
              it shall do so by way of a written contract that provides for, in
              substance, the same data protection obligations as those binding
              the data importer under these Clauses, including in terms of
              third-party beneficiary rights for data subjects. (8) The Parties
              agree that, by complying with this Clause, the data importer
              fulfils its obligations under Clause 8.8. The data importer shall
              ensure that the sub-processor complies with the obligations to
              which the data importer is subject pursuant to these Clauses.
            </p>
            <p>
              (c) The data importer shall provide, at the data exporter&apos;s
              request, a copy of such a sub-processor agreement and any
              subsequent amendments to the data exporter. To the extent
              necessary to protect business secrets or other confidential
              information, including personal data, the data importer may redact
              the text of the agreement prior to sharing a copy.
            </p>
            <p>
              (d) The data importer shall remain fully responsible to the data
              exporter for the performance of the sub-processor&apos;s
              obligations under its contract with the data importer. The data
              importer shall notify the data exporter of any failure by the
              sub-processor to fulfil its obligations under that contract.
            </p>
            <p>
              (e) The data importer shall agree on a third-party beneficiary
              clause with the sub-processor whereby &ndash; in the event, the
              data importer has factually disappeared, ceased to exist in law or
              has become insolvent &ndash; the data exporter shall have the
              right to terminate the sub-processor contract and to instruct the
              sub-processor to erase or return the personal data.
            </p>
            <h2>
              <strong>
                <em>Clause 10</em>
              </strong>
            </h2>
            <h2>
              <strong>Data subject rights</strong>
            </h2>
            <p>
              (a) The data importer shall promptly notify the data exporter of
              any request it has received from a data subject. It shall not
              respond to that request itself unless it has been authorised to do
              so by the data exporter.
            </p>
            <p>
              (b) The data importer shall assist the data exporter in fulfilling
              its obligations to respond to data subjects&apos; requests for the
              exercise of their rights under Regulation (EU) 2016/679. In this
              regard, the Parties shall set out in Annex II the appropriate
              technical and organisational measures, taking into account the
              nature of the processing, by which the assistance shall be
              provided, as well as the scope and the extent of the assistance
              required.
            </p>
            <p>
              (c) In fulfilling its obligations under paragraphs (a) and (b),
              the data importer shall comply with the instructions from the data
              exporter.
            </p>
            <h2>
              <strong>
                <em>Clause 11</em>
              </strong>
            </h2>
            <h2>
              <strong>Redress</strong>
            </h2>
            <p>
              (a) The data importer shall inform data subjects in a transparent
              and easily accessible format, through individual notice or on its
              website, of a contact point authorised to handle complaints. It
              shall deal promptly with any complaints it receives from a data
              subject.
            </p>
            <p>
              (b) In case of a dispute between a data subject and one of the
              Parties as regards compliance with these Clauses, that Party shall
              use its best efforts to resolve the issue amicably in a timely
              fashion. The Parties shall keep each other informed about such
              disputes and, where appropriate, cooperate in resolving them.
            </p>
            <p>
              (c) Where the data subject invokes a third-party beneficiary right
              pursuant to Clause 3, the data importer shall accept the decision
              of the data subject to:
            </p>
            <p>
              (i) lodge a complaint with the supervisory authority in the Member
              State of his/her habitual residence or place of work, or the
              competent supervisory authority pursuant to Clause 13;
            </p>
            <p>
              (ii) refer the dispute to the competent courts within the meaning
              of Clause 18.
            </p>
            <p>
              (d) The Parties accept that the data subject may be represented by
              a not-for-profit body, organisation or association under the
              conditions set out in Article 80(1) of Regulation (EU) 2016/679.
            </p>
            <p>
              (e) The data importer shall abide by a decision that is binding
              under the applicable EU or Member State law.
            </p>
            <p>
              (f) The data importer agrees that the choice made by the data
              subject will not prejudice his/her substantive and procedural
              rights to seek remedies in accordance with applicable laws.
            </p>
            <h2>
              <strong>
                <em>Clause 12</em>
              </strong>
            </h2>
            <h2>
              <strong>Liability</strong>
            </h2>
            <p>
              (a) Each Party shall be liable to the other Party/ies for any
              damages it causes the other Party/ies by any breach of these
              Clauses.
            </p>
            <p>
              (b) The data importer shall be liable to the data subject, and the
              data subject shall be entitled to receive compensation, for any
              material or non-material damages the data importer or its
              sub-processor causes the data subject by breaching the third-party
              beneficiary rights under these Clauses.
            </p>
            <p>
              (c) Notwithstanding paragraph (b), the data exporter shall be
              liable to the data subject, and the data subject shall be entitled
              to receive compensation, for any material or non-material damages
              the data exporter or the data importer (or its sub-processor)
              causes the data subject by breaching the third-party beneficiary
              rights under these Clauses. This is without prejudice to the
              liability of the data exporter and, where the data exporter is a
              processor acting on behalf of a controller, to the liability of
              the controller under Regulation (EU) 2016/679 or Regulation (EU)
              2018/1725, as applicable.
            </p>
            <p>
              (d) The Parties agree that if the data exporter is held liable
              under paragraph (c) for damages caused by the data importer (or
              its sub-processor), it shall be entitled to claim back from the
              data importer that part of the compensation corresponding to the
              data importer&apos;s responsibility for the damage.
            </p>
            <p>
              (e) Where more than one Party is responsible for any damage caused
              to the data subject as a result of a breach of these Clauses, all
              responsible Parties shall be jointly and severally liable and the
              data subject is entitled to bring an action in court against any
              of these Parties.
            </p>
            <p>
              (f) The Parties agree that if one Party is held liable under
              paragraph (e), it shall be entitled to claim back from the other
              Party/ies that part of the compensation corresponding to its/their
              responsibility for the damage.
            </p>
            <p>
              (g) The data importer may not invoke the conduct of a
              sub-processor to avoid its own liability.
            </p>
            <h2>
              <strong>
                <em>Clause 13</em>
              </strong>
            </h2>
            <h2>
              <strong>Supervision</strong>
            </h2>
            <p>
              (a) The parties agree that the supervisory authority with
              responsibility for ensuring compliance by the data exporter with
              Regulation (EU) 2016/679 as regards the data transfer shall be the
              Dutch Data Protection Authority (Autoriteit Persoonsgegevens),
              which shall act as a competent supervisory authority.
            </p>
            <p>
              (b) The data importer agrees to submit itself to the jurisdiction
              of and cooperate with the competent supervisory authority in any
              procedures aimed at ensuring compliance with these Clauses. In
              particular, the data importer agrees to respond to enquiries,
              submit to audits and comply with the measures adopted by the
              supervisory authority, including remedial and compensatory
              measures. It shall provide the supervisory authority with written
              confirmation that the necessary actions have been taken.
            </p>
            <h2>
              <strong>
                SECTION III &ndash; LOCAL LAWS AND OBLIGATIONS IN CASE OF ACCESS
                BY PUBLIC AUTHORITIES
              </strong>
            </h2>
            <h2>
              <strong>
                <em>Clause 14</em>
              </strong>
            </h2>
            <h2>
              <strong>
                Local laws and practices affecting compliance with the Clauses
              </strong>
            </h2>
            <p>
              (a) The Parties warrant that they have no reason to believe that
              the laws and practices in the third country of destination are
              applicable to the processing of the personal data by the data
              importer, including any requirements to disclose personal data or
              measures authorising access by public authorities, prevent the
              data importer from fulfilling its obligations under these Clauses.
              This is based on the understanding that laws and practices that
              respect the essence of the fundamental rights and freedoms and do
              not exceed what is necessary and proportionate in a democratic
              society to safeguard one of the objectives listed in Article 23(1)
              of Regulation (EU) 2016/679 are not in contradiction with these
              Clauses.
            </p>
            <p>
              (b) The Parties declare that in providing the warranty in
              paragraph (a), they have taken due account in particular the
              following elements:
            </p>
            <p>
              (i) the specific circumstances of the transfer, including the
              length of the processing chain, the number of actors involved and
              the transmission channels used; intended onward transfers; the
              type of recipient; the purpose of the processing; the categories
              and format of the transferred personal data; the economic sector
              in which the transfer occurs; the storage location of the data
              transferred;
            </p>
            <p>
              (ii) the laws and practices of the third country of
              destination&ndash; including those requiring the disclosure of
              data to public authorities or authorising access by such
              authorities &ndash; relevant in light of the specific
              circumstances of the transfer, and the applicable limitations and
              safeguards (12);
            </p>
            <p>
              (iii) any relevant contractual, technical or organisational
              safeguards put in place to supplement the safeguards under these
              Clauses, including measures applied during transmission and to the
              processing of the personal data in the country of destination.
            </p>
            <p>
              (c) The data importer warrants that, in carrying out the
              assessment under paragraph (b), it has made its best efforts to
              provide the data exporter with relevant information and agrees
              that it will continue to cooperate with the data exporter in
              ensuring compliance with these Clauses.
            </p>
            <p>
              (d) The Parties agree to document the assessment under paragraph
              (b) and make it available to the competent supervisory authority
              on request.
            </p>
            <p>
              (e) The data importer agrees to notify the data exporter promptly
              if, after having agreed to these Clauses and for the duration of
              the contract, it has reason to believe that it is or has become
              subject to laws or practices not in line with the requirements
              under paragraph (a), including following a change in the laws of
              the third country or a measure (such as a disclosure request)
              indicating an application of such laws in practice that is not in
              line with the requirements in paragraph (a).
            </p>
            <p>
              (f) Following a notification pursuant to paragraph (e), or if the
              data exporter otherwise has reason to believe that the data
              importer can no longer fulfil its obligations under these Clauses,
              the data exporter shall promptly identify appropriate measures
              (e.g. technical or organisational measures to ensure security and
              confidentiality) to be adopted by the data exporter and/or data
              importer to address the situation. The data exporter shall suspend
              the data transfer if it considers that no appropriate safeguards
              for such transfer can be ensured, or if instructed by the
              competent supervisory authority to do so. In this case, the data
              exporter shall be entitled to terminate the contract, insofar as
              it concerns the processing of personal data under these Clauses.
              If the contract involves more than two Parties, the data exporter
              may exercise this right to termination only with respect to the
              relevant Party, unless the Parties have agreed otherwise. Where
              the contract is terminated pursuant to this Clause, Clause 16(d)
              and (e) shall apply.
            </p>
            <h2>
              <strong>
                <em>Clause 15</em>
              </strong>
            </h2>
            <h2>
              <strong>
                Obligations of the data importer in case of access by public
                authorities
              </strong>
            </h2>
            <h2>
              <strong>15.1 Notification</strong>
            </h2>
            <p>
              (a) The data importer agrees to notify the data exporter and,
              where possible, the data subject promptly (if necessary with the
              help of the data exporter) if it:
            </p>
            <p>
              (i) receives a legally binding request from a public authority,
              including judicial authorities, under the laws of the country of
              destination for the disclosure of personal data transferred
              pursuant to these Clauses; such notification shall include
              information about the personal data requested, the requesting
              authority, the legal basis for the request and the response
              provided; or
            </p>
            <p>
              (ii) becomes aware of any direct access by public authorities to
              personal data transferred pursuant to these Clauses in accordance
              with the laws of the country of destination; such notification
              shall include all information available to the importer.
            </p>
            <p>
              (b) If the data importer is prohibited from notifying the data
              exporter and/or the data subject under the laws of the country of
              destination, the data importer agrees to use its best efforts to
              obtain a waiver of the prohibition, with a view to communicating
              as much information as possible, as soon as possible. The data
              importer agrees to document its best efforts in order to be able
              to demonstrate them at the request of the data exporter.
            </p>
            <p>
              (c) Where permissible under the laws of the country of
              destination, the data importer agrees to provide the data
              exporter, at regular intervals for the duration of the contract,
              with as much relevant information as possible on the requests
              received (in particular, number of requests, type of data
              requested, requesting authority/ies, whether requests have been
              challenged and the outcome of such challenges, etc.).
            </p>
            <p>
              (d) The data importer agrees to preserve the information pursuant
              to paragraphs (a) to (c) for the duration of the contract and make
              it available to the competent supervisory authority on request.
            </p>
            <p>
              (e) Paragraphs (a) to (c) are without prejudice to the obligation
              of the data importer pursuant to Clause 14(e) and Clause 16 to
              inform the data exporter promptly where it is unable to comply
              with these Clauses.
            </p>
            <h2>
              <strong>15.2 Review of legality and data minimisation</strong>
            </h2>
            <p>
              (a) The data importer agrees to review the legality of the request
              for disclosure, in particular, whether it remains within the
              powers granted to the requesting public authority, and to
              challenge the request if, after careful assessment, it concludes
              that there are reasonable grounds to consider that the request is
              unlawful under the laws of the country of destination, applicable
              obligations under international law and principles of
              international comity. The data importer shall, under the same
              conditions, pursue possibilities of appeal. When challenging a
              request, the data importer shall seek interim measures with a view
              to suspending the effects of the request until the competent
              judicial authority has decided on its merits. It shall not
              disclose the personal data requested until required to do so under
              the applicable procedural rules. These requirements are without
              prejudice to the obligations of the data importer under Clause
              14(e).
            </p>
            <p>
              (b) The data importer agrees to document its legal assessment and
              any challenge to the request for disclosure and, to the extent
              permissible under the laws of the country of destination, make the
              documentation available to the data exporter. It shall also make
              it available to the competent supervisory authority on request.
            </p>
            <p>
              (c) The data importer agrees to provide the minimum amount of
              information permissible when responding to a request for
              disclosure, based on a reasonable interpretation of the request.
            </p>
            <h2>
              <strong>SECTION IV &ndash; FINAL PROVISIONS</strong>
            </h2>
            <h2>
              <strong>
                <em>Clause 16</em>
              </strong>
            </h2>
            <h2>
              <strong>Non-compliance with the Clauses and termination</strong>
            </h2>
            <p>
              (a) The data importer shall promptly inform the data exporter if
              it is unable to comply with these Clauses, for whatever reason.
            </p>
            <p>
              (b) In the event that the data importer is in breach of these
              Clauses or unable to comply with these Clauses, the data exporter
              shall suspend the transfer of personal data to the data importer
              until compliance is again ensured or the contract is terminated.
              This is without prejudice to Clause 14(f).
            </p>
            <p>
              (c) The data exporter shall be entitled to terminate the contract,
              insofar as it concerns the processing of personal data under these
              Clauses, where:
            </p>
            <p>
              (i) the data exporter has suspended the transfer of personal data
              to the data importer pursuant to paragraph (b) and compliance with
              these Clauses is not restored within a reasonable time and in any
              event within one month of suspension;
            </p>
            <p>
              (ii) the data importer is in substantial or persistent breach of
              these Clauses; or
            </p>
            <p>
              (iii) the data importer fails to comply with a binding decision of
              a competent court or supervisory authority regarding its
              obligations under these Clauses.
            </p>
            <p>
              In these cases, it shall inform the competent supervisory
              authority of such non-compliance. Where the contract involves more
              than two Parties, the data exporter may exercise this right to
              termination only with respect to the relevant Party, unless the
              Parties have agreed otherwise.
            </p>
            <p>
              (d) Personal data that has been transferred prior to the
              termination of the contract pursuant to paragraph (c) shall at the
              choice of the data exporter immediately be returned to the data
              exporter or deleted in its entirety. The same shall apply to any
              copies of the data. The data importer shall certify the deletion
              of the data to the data exporter. Until the data is deleted or
              returned, the data importer shall continue to ensure compliance
              with these Clauses. In case of local laws applicable to the data
              importer that prohibit the return or deletion of the transferred
              personal data, the data importer warrants that it will continue to
              ensure compliance with these Clauses and will only process the
              data to the extent and for as long as required under that local
              law.
            </p>
            <p>
              (e) Either Party may revoke its agreement to be bound by these
              Clauses where (i) the European Commission adopts a decision
              pursuant to Article 45(3) of Regulation (EU) 2016/679 that covers
              the transfer of personal data to which these Clauses apply; or
              (ii) Regulation (EU) 2016/679 becomes part of the legal framework
              of the country to which the personal data is transferred. This is
              without prejudice to other obligations applying to the processing
              in question under Regulation (EU) 2016/679.
            </p>
            <h2>
              <strong>
                <em>Clause 17</em>
              </strong>
            </h2>
            <h2>
              <strong>Governing law </strong>
            </h2>
            <p>
              These Clauses shall be governed by the law of the EU Member State
              in which the data exporter is established. Where such law does not
              allow for third-party beneficiary rights, they shall be governed
              by the law of another EU Member State that does allow for
              third-party beneficiary rights.
            </p>
            <h2>
              <strong>
                <em>Clause 18</em>
              </strong>
            </h2>
            <h2>
              <strong>Choice of forum and jurisdiction</strong>
            </h2>
            <p>
              (a) Any dispute arising from these Clauses shall be resolved by
              the courts of the United States.
            </p>
            <p>
              (b) The Parties agree that those shall be the courts of the state
              of Wyoming.
            </p>
            <p>
              (c) A data subject may also bring legal proceedings against the
              data exporter and/or data importer before the courts of the Member
              State in which he/she has his/her habitual residence.
            </p>
            <p>
              (d) The Parties agree to submit themselves to the jurisdiction of
              such courts.
            </p>
            <p>
              <strong>
                Appendix 1 to Managerize Standard Contractual Clauses
              </strong>
            </p>

            <p>This Appendix forms part of the Clauses</p>

            <p>
              <strong>Data exporter</strong> The data exporter is the
              non-Managerize legal entity that is a party to the Clauses.
            </p>

            <p>
              <strong>Data importer.</strong> The data importer is: Managerize
            </p>
            <p>
              if the data exporter is transferring personal data to Managerize
              under the Data Processing Agreement; or
            </p>

            <p>
              <strong>Data subjects.</strong> The personal data transferred
              concern the following categories of data subjects: Data subjects
              include individuals about whom data that originated in the EEA is
              provided to Managerize via the Business Services by (or at the
              direction of) the data exporter.&emsp;
            </p>

            <p>
              <strong>Categories of data.</strong> The personal data transferred
              concerns the following categories of data: Data relating to
              individuals provided to Managerize via the Business Services by
              (or at the direction of) data exporter, as specified in Schedule
              1: Details of Data Processing of the Data Processing Agreement.
            </p>

            <p>
              <strong>Special categories of data</strong> (if appropriate) The
              personal data transferred concerns the following special
              categories of data: None
            </p>

            <p>
              Processing operations Managerize will process the personal data
              for the purposes of providing the Business Services to the data
              exporter in accordance with and as described in the Data
              Processing Agreement, and these Clauses.
            </p>

            <p>
              <strong>
                Appendix 2 to Managerize Standard Contractual Clauses
              </strong>
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <p>
              <strong>This Appendix forms part of the Clauses.</strong>
            </p>

            <p>
              Description of the technical and organisational security measures
              implemented by the data importer in accordance with Clauses 4(c)
              and 5(c). The data importer currently abides by the security
              standards in Schedule 2 - Managerize Security Measures of the Data
              Processing Agreement. The data importer may update or modify these
              security standards from time to time.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default SCC;
