import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { logoutUser } from "../../apiService/apiService";
import "./DashboardNavbar.css";

const DashboardNavbar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogout = useCallback(() => {
    logoutUser();
    navigate("/");
  }, [navigate]);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleLinkClick = () => {
    closeMenu();
  };

  return (
    <div className="dashboard-navbar">
      <div className="navbar-header">Dashboard</div>

      <Menu
        isOpen={menuOpen}
        onStateChange={({ isOpen }) => setMenuOpen(isOpen)}
      >
        <Link className="menu-item" to="/dashboard" onClick={handleLinkClick}>
          Home
        </Link>
        <Link className="menu-item" to="/gigs" onClick={handleLinkClick}>
          Gigs
        </Link>
        <Link
          className="menu-item"
          to="/dashboard/settings"
          onClick={handleLinkClick}
        >
          Settings
        </Link>
        <Link className="menu-item" to="/onboarding" onClick={handleLinkClick}>
          Onboarding
        </Link>
        <span className="menu-item" onClick={handleLogout}>
          Logout
        </span>
      </Menu>

      <a className="navbar-links-logo" href="/#">
        <img src="/images/logo/logo.svg" alt="Logo" />
      </a>

      <div className="navbar-links">
        <Link className="navbar-item" to="/dashboard" onClick={handleLinkClick}>
          <img src="/icons/menu.svg" alt="Home" />
        </Link>
        <Link className="navbar-item" to="/gigs" onClick={handleLinkClick}>
          <img src="/icons/gigs.svg" alt="Gigs" />
        </Link>
        <Link
          className="navbar-item"
          to="/dashboard/settings"
          onClick={handleLinkClick}
        >
          <img src="/icons/settings.svg" alt="Settings" />
        </Link>
        <Link
          className="navbar-item"
          to="/onboarding"
          onClick={handleLinkClick}
        >
          <img src="/icons/info.svg" alt="Onboarding" />
        </Link>
        <span className="navbar-item" onClick={handleLogout}>
          <img src="/icons/logout.svg" alt="Logout" />
        </span>
      </div>
    </div>
  );
};

export default DashboardNavbar;
