import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import EmployeeDetailInfoCard from "../../components/EmployeeDetailInfoCard/EmployeeDetailInfoCard";
import EmployeeDetailHoursWorkedChart from "../../components/EmployeeDetailHoursWorkedChart/EmployeeDetailHoursWorkedChart";
import EmployeeDetailIsClockedIn from "../../components/EmployeeDetailIsClockedIn/EmployeeDetailIsClockedIn";
import EmployeeDetailEarnings from "../../components/EmployeeDetailEarnings/EmployeeDetailEarnings";
import {
  getEmployeeById,
  getHoursWorkedPerDayByEmployeeId,
} from "../../apiService/apiService";
import Loader from "../../components/Loader/Loader";
import "./EmployeeDetail.css";

interface Employee {
  employeeId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  gender: string;
  dateOfBirth: string;
  hireDate: string;
  jobTitle: string;
  paymentType: string;
  paymentAmount: number;
  status: string;
}

interface HoursWorkedData {
  date: string;
  totalHoursWorked: number;
}

const EmployeeDetail = () => {
  const { employeeId } = useParams<{ employeeId: string }>();

  const [employee, setEmployee] = useState<Employee | null>(null);
  const [hoursWorkedData, setHoursWorkedData] = useState<HoursWorkedData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchEmployee = async () => {
      setLoading(true);
      if (!employeeId) {
        setError("Employee ID is missing");
        setLoading(false);
        return;
      }
      try {
        const employeeData = await getEmployeeById(employeeId);
        setEmployee(employeeData);

        const hoursData = await getHoursWorkedPerDayByEmployeeId(employeeId);
        setHoursWorkedData(hoursData);
      } catch (error) {
        console.error("Failed to fetch employee details:", error);
        setError("Failed to load employee details.");
      } finally {
        setLoading(false);
      }
    };

    fetchEmployee();
  }, [employeeId]);

  if (error) return <div>{error}</div>;
  if (loading) return <Loader isLoading={loading} />;

  return (
    <div className="employee-detail">
      <title>Employee Details</title>
      <DashboardNavbar />
      <div className="content">
        {employee && employeeId && (
          <>
            <EmployeeDetailInfoCard employee={employee} />
            <EmployeeDetailHoursWorkedChart
              hoursWorkedData={hoursWorkedData}
              employeeId={employeeId}
            />
            <EmployeeDetailIsClockedIn employeeId={employeeId} />
            <EmployeeDetailEarnings
              employeeId={employeeId}
              paymentType={employee.paymentType}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default EmployeeDetail;
