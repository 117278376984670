import React from "react";
import { Link } from "react-router-dom";
import "./EULA.css";

const EULA = () => {
  return (
    <div className="eula-page">
      <title>EULA</title>
      <div className="banner">
        <img
          src="/images/header/banner.png"
          alt="Banner"
          className="banner-image"
        />
      </div>

      <div className="eula-page-content">
        <Link to="/">
          <img src="/images/logo/logo.svg" alt="Logo" className="logo" />
        </Link>

        <div className="eula-container">
          <header>END USER LICENCE AGREEMENT</header>
          <section>
            <p>
              <strong>Last updated December 26, 2024</strong>
            </p>

            <p>
              Managerize: Workforce is licensed to You (End-User) by Managerize,
              based in the United States (&apos;<strong>Licensor</strong>
              &apos;), for use only under the terms of this Licence Agreement.
            </p>

            <p>
              By downloading the Licensed Application from Apple&apos;s software
              distribution platform (&apos;App Store&apos;) and Google&apos;s
              software distribution platform (&apos;Play Store&apos;), and any
              update thereto (as permitted by this Licence Agreement), You
              indicate that You agree to be bound by all of the terms and
              conditions of this Licence Agreement, and that You accept this
              Licence Agreement. App Store and Play Store are referred to in
              this Licence Agreement as &apos;<strong>Services</strong>&apos;.
            </p>

            <p>
              The parties of this Licence Agreement acknowledge that the
              Services are not a Party to this Licence Agreement and are not
              bound by any provisions or obligations with regard to the Licensed
              Application, such as warranty, liability, maintenance, and support
              thereof. Managerize: Workforce, not the Services, is solely
              responsible for the Licensed Application and the content thereof.
            </p>

            <p>
              This Licence Agreement may not provide for usage rules for the
              Licensed Application that are in conflict with the latest{" "}
              <a href="https://www.apple.com/legal/internet-services/itunes/us/terms.html">
                Apple Media Services Terms and Conditions
              </a>{" "}
              and{" "}
              <a href="https://play.google.com/intl/en_US/about/play-terms/">
                Google Play Terms of Service
              </a>{" "}
              (&apos;<strong>Usage Rules</strong>&apos;). Managerize: Workforce
              acknowledges that it had the opportunity to review the Usage Rules
              and this Licence Agreement does not conflict with them.
            </p>

            <p>
              Managerize: Workforce when purchased or downloaded through the
              Services, is licensed to You for use only under the terms of this
              Licence Agreement. The Licensor reserves all rights not expressly
              granted to You. Managerize: Workforce is to be used on devices
              that operate with Apple&apos;s operating systems (&apos;iOS&apos;
              and &apos;Mac OS&apos;) or Google&apos;s operating system
              (&apos;Android&apos;).
            </p>

            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              <strong>1. THE APPLICATION</strong>
            </strong>

            <p>
              Managerize: Workforce (&apos;<strong>Licensed Application</strong>
              &apos;) is an app designed to streamline workforce operations. It
              enables employers to track employee data, manage clock-ins and
              clock-outs, process payments, and oversee work schedules. By
              combining robust tools with secure data handling, Managerize
              simplifies employee management and enhances organizational
              efficiency&mdash; and is customized for iOS and Android mobile
              devices (&apos;<strong>Devices</strong>&apos;).&nbsp;
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              <strong>2. SCOPE OF LICENCE</strong>
            </strong>

            <p>
              2.1&nbsp; You are given a non-transferable, non-exclusive,
              non-sublicensable license to install and use the Licensed
              Application on any Devices that You (End-User) own or control and
              as permitted by the Usage Rules, with the exception that such
              Licensed Application may be accessed and used by other accounts
              associated with You (End-User, The Purchaser) via Family Sharing
              or volume purchasing.
            </p>

            <p>
              2.2&nbsp; This license will also govern any updates of the
              Licensed Application provided by the Licensor that replaces,
              repair, and/or supplement the first Licensed Application unless a
              separate license is provided for such update, in which case the
              terms of that new license will govern.
            </p>

            <p>
              2.3&nbsp; You may not share or make the Licensed Application
              available to third parties (unless to the degree allowed by the
              Usage Rules, and with Managerize: Workforce&apos;s prior written
              consent), sell, rent, lend, lease, or otherwise redistribute the
              Licensed Application.
            </p>

            <p>
              2.4&nbsp; You may not reverse engineer, translate, disassemble,
              integrate, decompile, remove, modify, combine, create derivative
              works or updates of, adapt, or attempt to derive the source code
              of the Licensed Application, or any part thereof (except with
              Managerize: Workforce&apos;s prior written consent).
            </p>

            <p>
              2.5&nbsp; You may not copy (excluding when expressly authorized by
              this license and the Usage Rules) or alter the Licensed
              Application or portions thereof. You may create and store copies
              only on devices that You own or control for backup keeping under
              the terms of this license, the Usage Rules, and any other terms
              and conditions that apply to the device or software used. You may
              not remove any intellectual property notices. You acknowledge that
              no unauthorized third parties may gain access to these copies at
              any time. If you sell your Devices to a third party, you must
              remove the Licensed Application from the Devices before doing so.
            </p>

            <p>
              2.6&nbsp; Violations of the obligations mentioned above, as well
              as the attempt of such infringement, may be subject to prosecution
              and damages.
            </p>

            <p>
              2.7&nbsp; Licensor reserves the right to modify the terms and
              conditions of licensing.
            </p>

            <p>
              2.8&nbsp; Nothing in this license should be interpreted to
              restrict third-party terms. When using the Licensed Application,
              You must ensure that You comply with applicable third-party terms
              and conditions.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              <strong>3. TECHNICAL REQUIREMENTS</strong>
            </strong>

            <p>
              3.1 The licensor attempts to keep the Licensed Application updated
              so that it complies with modified/new versions of the firmware and
              new hardware. You are not granted right to claim such an update.
            </p>

            <p>
              3.2&nbsp; You acknowledge that it is Your responsibility to
              confirm and determine that the app end-user device on which You
              intend to use the Licensed Application satisfies the technical
              specifications mentioned above.
            </p>

            <p>
              3.3&nbsp; Licensor reserves the right to modify the technical
              specifications as it sees appropriate at any time.
            </p>
            <strong>
              <strong>&nbsp;</strong>
            </strong>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              <strong>4. MAINTENANCE AND SUPPORT</strong>
            </strong>

            <p>
              4.1&nbsp; The Licensor is solely responsible for providing any
              maintenance and support services for this Licensed Application.
              You can reach the Licensor at the email address listed in the App
              Store or Play Store Overview for this Licensed Application.
            </p>

            <p>
              4.2&nbsp; Managerize: Workforce and the End-User acknowledge that
              the Services have no obligation whatsoever to furnish any
              maintenance and support services with respect to the Licensed
              Application.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              <strong>5. USE OF DATA</strong>
            </strong>

            <p>
              You acknowledge that the Licensor will be able to access and
              adjust Your downloaded Licensed Application content and Your
              personal information, and that the Licensor&apos;s use of such
              material and information is subject to Your legal agreements with
              the Licensor and the Licensor&apos;s privacy policy.
            </p>

            <p>
              You acknowledge that the Licensor may periodically collect and use
              technical data and related information about your device, system,
              application software, and peripherals, offer product support,
              facilitate the software updates, and for purposes of providing
              other services to you (if any) related to the Licensed
              Application. Licensor may also use this information to improve its
              products or to provide services or technologies to you, as long as
              it is in a form that does not personally identify you.
            </p>
            <strong>
              <strong>&nbsp;</strong>
            </strong>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              <strong>6. CONTRIBUTION LICENCE</strong>
            </strong>

            <p>
              By posting your Contributions to any part of the Licensed
              Application, you automatically grant, and represent and warrant
              that you have the right to grant, to us an unrestricted,
              unlimited, irrevocable, perpetual, non-exclusive, transferable,
              royalty-free, fully-paid, worldwide right, and license to host,
              use copy, reproduce, disclose, sell, resell, publish, broadcast,
              retitle, archive, store, cache, publicly display, reformat,
              translate, transmit, excerpt (in whole or in part), and distribute
              such Contributions (including, without limitation, your image and
              voice) for any purpose, commercial advertising, or otherwise, and
              to prepare derivative works of, or incorporate in other works,
              such as Contributions, and grant and authorize sublicenses of the
              foregoing. The use and distribution may occur in any media format
              and through any media channels.
            </p>

            <p>
              This license will apply to any form, media, or technology now
              known or hereafter developed, and includes our use of your name,
              company name, and franchise name, as applicable, and any of the
              trademarks, service marks, trade names, logos, and personal and
              commercial images you provide. You waive all moral rights in your
              Contributions, and you warrant that moral rights have not
              otherwise been asserted in your Contributions.
            </p>

            <p>
              We do not assert any ownership over your Contributions. You retain
              full ownership of all of your Contributions and any intellectual
              property rights or other proprietary rights associated with your
              Contributions. We are not liable for any statements or
              representations in your Contributions provided by you in any area
              in the Licensed Application. You are solely responsible for your
              Contributions to the Licensed Application and you expressly agree
              to exonerate us from any and all responsibility and to refrain
              from any legal action against us regarding your Contributions.
            </p>

            <p>
              We have the right, in our sole and absolute discretion, (1) to
              edit, redact, or otherwise change any Contributions; (2) to
              recategorize any Contributions to place them in more appropriate
              locations in the Licensed Application; and (3) to prescreen or
              delete any Contributions at any time and for any reason, without
              notice. We have no obligation to monitor your Contributions.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              <strong>7. LIABILITY</strong>
            </strong>

            <p>
              7.1 The Licensor&apos;s responsibility in the case of violation of
              obligations and tort shall be limited to intent and gross
              negligence. Only in case of a breach of essential contractual
              duties (cardinal obligations), the Licensor shall also be liable
              in case of slight negligence. In any case, liability shall be
              limited to foreseeable, contractually typical damages. The
              limitation mentioned above does not apply to injuries to life,
              limb, or health.
            </p>

            <p>
              7.2 The Licensor takes no accountability or responsibility for any
              damages caused due to a breach of duties according to Section 2 of
              this Licence Agreement. To avoid data loss, You are required to
              make use of backup functions of the Licensed Application to the
              extent allowed by applicable third-party terms and conditions of
              use. You are aware that in case of alterations or manipulations of
              the Licensed Application, You will not have access to the Licensed
              Application.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              <strong>8. WARRANTY</strong>
            </strong>

            <p>
              8.1&nbsp; Licensor warrants that the Licensed Application is free
              of spyware, trojan horses, viruses, or any other malware at the
              time of Your download. The licensor warrants that the Licensed
              Application works as described in the user documentation.
            </p>

            <p>
              8.2&nbsp; No warranty is provided for the Licensed Application
              that is not executable on the device, that has been unauthorisedly
              modified, handled inappropriately or culpably, combined or
              installed with inappropriate hardware or software, used with
              inappropriate accessories, regardless if by Yourself or by third
              parties, or if there are any other reasons outside of Managerize:
              Workforce&apos;s sphere of influence that affect the executability
              of the Licensed Application.
            </p>

            <p>
              8.3&nbsp; You are required to inspect the Licensed Application
              immediately after installing it and notify Managerize: Workforce
              about issues discovered without delay by email provided in{" "}
              <a href="#_wal4qb5fw7q9">Contact Information</a>. The defect
              report will be taken into consideration and further investigated
              if it has been emailed within a period of thirty (30) days after
              discovery.
            </p>

            <p>
              8.4&nbsp; If we confirm that the Licensed Application is
              defective, Managerize: Workforce reserves a choice to remedy the
              situation either by means of solving the defect or substitute
              delivery.
            </p>

            <p>
              8.5&nbsp; In the event of any failure of the Licensed Application
              to conform to any applicable warranty, You may notify the Services
              Store Operator and Your Licensed Application purchase price will
              be refunded to You. To the maximum extent permitted by applicable
              law, the Services Store Operator will have no other warranty
              obligation whatsoever with respect to the Licensed Application,
              and any other losses, claims, damages, liabilities, expenses, and
              costs attributable to any negligence to adhere to any warranty.
            </p>

            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              <strong>9. PRODUCT CLAIMS</strong>
            </strong>

            <p>
              Managerize: Workforce and the End-User acknowledge that
              Managerize: Workforce, and not the Services, is responsible for
              addressing any claims of the End-User or any third party relating
              to the Licensed Application or the End-User&apos;s possession
              and/or use of that Licensed Application, including, but not
              limited to:
            </p>

            <p>(i) product liability claims;</p>
            <p>
              (ii) any claim that the Licensed Application fails to conform to
              any applicable legal or regulatory requirement; and
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              <strong>10. CONTACT INFORMATION</strong>
            </strong>

            <p>
              For general inquiries, complaints, questions, or claims concerning
              the Licensed Application, please contact us at:{" "}
              <a href="mailto:contact@managerize.com">contact@managerize.com</a>
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              <strong>11. TERMINATION</strong>
            </strong>

            <p>
              The license is valid until terminated by Managerize: Workforce or
              by You. Your rights under this license will terminate
              automatically and without notice from Managerize: Workforce if You
              fail to adhere to any term(s) of this license. Upon Licence
              termination, You shall stop all use of the Licensed Application,
              and destroy all copies, full or partial, of the Licensed
              Application.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              <strong>
                12. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
              </strong>
            </strong>

            <p>
              Managerize: Workforce represents and warrants that Managerize:
              Workforce will comply with applicable third-party terms of
              agreement when using a Licensed Application.
            </p>

            <p>
              In accordance with Section 9 of the &apos;Instructions for Minimum
              Terms of Developer&apos;s End-User Licence Agreement&apos;, both
              Apple and Google and their subsidiaries shall be third-party
              beneficiaries of this End User Licence Agreement and &mdash; upon
              Your acceptance of the terms and conditions of this Licence
              Agreement, both Apple and Google will have the right (and will be
              deemed to have accepted the right) to enforce this End User
              Licence Agreement against You as a third-party beneficiary
              thereof.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              <strong>13. INTELLECTUAL PROPERTY RIGHTS</strong>
            </strong>

            <p>
              Managerize: Workforce and the End-User acknowledge that, in the
              event of any third-party claim that the Licensed Application or
              the End-User&apos;s possession and use of that Licensed
              Application infringes on the third party&apos;s intellectual
              property rights, Managerize: Workforce, and not the Services, will
              be solely responsible for the investigation, defense, settlement,
              and discharge or any such intellectual property infringement
              claims.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              <strong>14. APPLICABLE LAW</strong>
            </strong>

            <p>
              This License Agreement is governed by and construed in accordance
              with the laws of the State of Wyoming applicable to agreements
              made and to be entirely performed within the State of Wyoming,
              without regard to its conflict of law principles.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              <strong>15. MISCELLANEOUS</strong>
            </strong>

            <p>
              15.1&nbsp; If any of the terms of this agreement should be or
              become invalid, the validity of the remaining provisions shall not
              be affected. Invalid terms will be replaced by valid ones
              formulated in a way that will achieve the primary purpose.
            </p>
            <p>
              15.2&nbsp; Collateral agreements, changes, and amendments are only
              valid if laid down in writing. The preceding clause can only be
              waived in writing.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EULA;
