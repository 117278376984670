import React from "react";
import { Link } from "react-router-dom";
import "./Pricing.css";

const Pricing = () => {
  return (
    <div className="pricing-page">
      <title>Pricing</title>
      <div className="banner">
        <img
          src="/images/header/banner.png"
          alt="Banner"
          className="banner-image"
        />
      </div>

      <div className="pricing-page-content">
        <Link to="/">
          <img src="/images/logo/logo.svg" alt="Logo" className="logo" />
        </Link>

        <div className="pricing-container">
          <header>Choose Plan</header>

          {/*====== PRICING PART START ======*/}
          <section id="pricing">
            <div className="cards">
              <div className="card shadow">
                <div className="pricing-text">
                  <div className="pack">Starter</div>
                  <div id="basic" className="price bottom-bar">
                    $49.99
                  </div>
                  <div className="bottom-bar">
                    Up to <strong>7</strong> employees
                  </div>
                  <div className="bottom-bar">Attendance tracking</div>
                  <div className="bottom-bar">Earnings reports</div>
                  <div className="bottom-bar">Location tracking</div>
                  <div className="bottom-bar">Schedule management</div>
                  <div className="bottom-bar">Employee records</div>
                  <div className="bottom-bar">Employee communication</div>
                  <div className="bottom-bar">Task management</div>
                  <a href="https://buy.stripe.com/test_14k8zdd8S3iUfsYfYY">
                    <button className="pricing-btn">Get started</button>
                  </a>
                </div>
              </div>
              <div className="card active">
                <div className="pricing-text">
                  <div className="pack">Growth</div>
                  <div id="professional" className="price bottom-bar">
                    $99.99
                  </div>
                  <div className="bottom-bar">
                    Up to <strong>15</strong> employees
                  </div>
                  <div className="bottom-bar">Attendance tracking</div>
                  <div className="bottom-bar">Earnings reports</div>
                  <div className="bottom-bar">Location tracking</div>
                  <div className="bottom-bar">Schedule management</div>
                  <div className="bottom-bar">Employee records</div>
                  <div className="bottom-bar">Employee communication</div>
                  <div className="bottom-bar">Task management</div>
                  <a href="https://buy.stripe.com/test_28o8zdc4O7zadkQ9AB">
                    <button className="active-btn">Get started</button>
                  </a>
                </div>
              </div>
              <div className="card shadow">
                <div className="pricing-text">
                  <div className="pack">Enterprise</div>
                  <div id="basic" className="price bottom-bar">
                    Custom
                  </div>
                  <div className="bottom-bar">
                    <strong>Unlimited</strong> employees
                  </div>
                  <div className="bottom-bar">Attendance tracking</div>
                  <div className="bottom-bar">Earnings reports</div>
                  <div className="bottom-bar">Location tracking</div>
                  <div className="bottom-bar">Schedule management</div>
                  <div className="bottom-bar">Employee records</div>
                  <div className="bottom-bar">Employee communication</div>
                  <div className="bottom-bar">Task management</div>
                  <button className="pricing-btn">Contact us</button>
                </div>
              </div>
            </div>
          </section>
          {/*====== PRICING PART END ======*/}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
