import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./FAQ.css";

interface FAQItem {
  question: string;
  answer: React.ReactNode;
}

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleAnswer = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData: FAQItem[] = [
    {
      question: "What is Managerize?",
      answer:
        "Managerize is an advanced workforce management software designed specifically for businesses that rely on field workers. It streamlines a variety of operations, including employee scheduling, time tracking, gig assignment, and location tracking. With Managerize, businesses can easily assign tasks or gigs to workers, track their real-time locations, and ensure that the right people are in the right place at the right time. The platform also provides comprehensive reporting tools to monitor employee performance, manage payroll, and keep a close eye on productivity. Whether you're managing a small team or a large workforce, Managerize offers the flexibility and efficiency needed to optimize field operations and boost business performance.",
    },
    {
      question: "Who can use Managerize?",
      answer:
        "Managerize is designed for businesses and organizations of all sizes that rely on field workers and need an efficient way to manage and optimize their workforce. Whether you're a small business with just a few employees or a large enterprise with a vast, mobile team, Managerize offers scalable solutions to suit your needs. It’s ideal for industries such as construction, delivery services, maintenance, healthcare, moving companies, cleaning services, landscaping, transportation, and more. Managerize helps businesses in these sectors streamline scheduling, track performance, assign tasks, and monitor real-time locations, making it easier to manage workers in the field. No matter the size or nature of your business, Managerize can improve productivity, enhance communication, and ensure your workforce operates at its best.",
    },
    {
      question: "How do I create an account?",
      answer:
        "To create an account, simply click the 'Sign Up' button on our homepage, provide your business details, and set up your account.",
    },
    {
      question: "What services does Managerize offer?",
      answer:
        "Managerize offers a comprehensive suite of features designed to help businesses manage their workforce more efficiently. Key services include employee scheduling, which allows you to easily create and manage shift schedules to ensure the right number of workers are available at the right time. Time tracking helps you monitor employee hours in real-time, with location-based tracking to ensure accuracy and reduce errors. Gig assignment enables you to seamlessly assign tasks to field workers, optimizing task distribution and progress tracking. The platform also integrates with popular payroll systems, streamlining wage calculations and reducing administrative overhead. Location tracking ensures your employees are in the right place at the right time, while detailed reporting provides valuable insights into employee performance, job completion, and hours worked, helping you make data-driven decisions. With these powerful tools, Managerize helps businesses from all industries optimize their workforce, improve productivity, and ensure smooth day-to-day operations.",
    },
    {
      question: "How do I get support?",
      answer:
        "If you need assistance, you can contact our customer support team via email at contact@managerize.com.",
    },
    {
      question: "Is my data secure with Managerize?",
      answer:
        "Yes, we take data security seriously. We use industry-standard encryption methods to protect your data and ensure that it is handled securely.",
    },
  ];

  return (
    <div className="FAQ">
      <title>FAQ</title>
      <div className="banner">
        <img
          src="/images/header/banner.png"
          alt="Banner"
          className="banner-image"
        />
      </div>

      <div className="FAQ-content">
        <Link to="/">
          <img src="/images/logo/logo.svg" alt="Logo" className="logo" />
        </Link>

        <div className="FAQ-container">
          <header>FAQ</header>
          <section>
            {faqData.map((item, index) => (
              <div key={index}>
                <h2
                  onClick={() => toggleAnswer(index)}
                  className="faq-question"
                >
                  {item.question}
                  <span
                    className={`faq-toggle-icon ${openIndex === index ? "open" : ""}`}
                  >
                    {openIndex === index ? "−" : "+"}
                  </span>
                </h2>
                {openIndex === index && (
                  <p className="faq-answer">{item.answer}</p>
                )}
                <div className="faq-separator"></div> {/* Separator line */}
              </div>
            ))}
          </section>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
