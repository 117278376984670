import React, { useState, useEffect, useRef } from "react";
import {
  getAllEmployeesForLoggedInUser,
  getMessagesForEmployeeAndCompany,
  sendMessage,
} from "../../apiService/apiService";
import Loader from "../Loader/Loader";
import "./OverviewChat.css";
import { EmployeeLimitedInfo, Message } from "../../constants/interfaces";
import { getCurrentIsoTime } from "../../utilities/utilities";

const OverviewChat = () => {
  const [employees, setEmployees] = useState<EmployeeLimitedInfo[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [selectedEmployee, setSelectedEmployee] =
    useState<EmployeeLimitedInfo | null>(null);
  const [newMessage, setNewMessage] = useState<string>("");
  const [isChatView, setIsChatView] = useState<boolean>(false);
  const [employeesLoading, setEmployeesLoading] = useState<boolean>(true);
  const [messagesLoading, setMessagesLoading] = useState<boolean>(false);

  // Ref to the message container
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const fetchEmployees = async () => {
      setEmployeesLoading(true);
      try {
        const data = await getAllEmployeesForLoggedInUser();
        setEmployees(data);
      } catch (error) {
        console.error("Failed to fetch employees:", error);
      } finally {
        setEmployeesLoading(false);
      }
    };
    fetchEmployees();
  }, []);

  useEffect(() => {
    if (selectedEmployee && isChatView) {
      const fetchMessages = async () => {
        setMessagesLoading(true);
        try {
          const data = await getMessagesForEmployeeAndCompany(
            selectedEmployee.employeeId,
          );
          setMessages(data);
        } catch (error) {
          console.error("Failed to fetch messages:", error);
        } finally {
          setMessagesLoading(false);
        }
      };
      fetchMessages();
    }
  }, [selectedEmployee, isChatView]);

  // Scroll to the bottom whenever messages change or a new employee is selected
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [messages, isChatView]);

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "") {
      setMessagesLoading(true);
      try {
        const message: Message = {
          employeeId: selectedEmployee!.employeeId,
          content: newMessage,
          sender: "Company",
          companyId: selectedEmployee!.companyId ?? 0,
          timestamp: getCurrentIsoTime(),
        };

        await sendMessage(message);
        setMessages((prevMessages) => [...prevMessages, message]);
        setNewMessage("");
      } catch (error) {
        console.error("Failed to send message:", error);
      } finally {
        setMessagesLoading(false);
      }
    }
  };

  const handleSelectEmployee = (employee: EmployeeLimitedInfo) => {
    setSelectedEmployee(employee);
    setIsChatView(true);
  };

  const handleBackToEmployeeList = () => {
    setIsChatView(false);
    setSelectedEmployee(null);
    setMessages([]);
  };

  return (
    <div className="overview-chat">
      <div className="chat-card">
        <header className="chat-header">
          <div className="chat-header-top">
            <h2>Employee Chats</h2>
          </div>
          {isChatView && selectedEmployee && (
            <div className="chat-header-bottom">
              <button
                className="back-button"
                onClick={handleBackToEmployeeList}
              >
                <img src="/icons/back.svg" alt="Back" />
              </button>
              <h3>
                {selectedEmployee.firstName} {selectedEmployee.lastName}
              </h3>
            </div>
          )}
        </header>

        {!isChatView ? (
          <div className="employee-list">
            {employeesLoading ? (
              <Loader isLoading={employeesLoading} />
            ) : (
              employees.map((employee) => (
                <div
                  key={employee.employeeId}
                  className={`employee-item ${selectedEmployee?.employeeId === employee.employeeId ? "active" : ""}`}
                  onClick={() => handleSelectEmployee(employee)}
                >
                  <span>
                    {employee.firstName} {employee.lastName}
                  </span>
                </div>
              ))
            )}
          </div>
        ) : (
          <div className="chat-section">
            <div className="messages-container">
              {messagesLoading ? (
                <Loader isLoading={messagesLoading} />
              ) : messages.length === 0 ? (
                <div className="no-messages">No messages</div>
              ) : (
                messages.map((message, index) => (
                  <div
                    key={index}
                    className={`message ${message.sender === "Company" ? "company-message" : "employee-message"}`}
                  >
                    <strong>
                      {message.sender === "Company"
                        ? "You"
                        : `${selectedEmployee?.firstName} ${selectedEmployee?.lastName}`}
                      :
                    </strong>
                    {message.content}
                  </div>
                ))
              )}
              <div ref={messagesEndRef} />
            </div>

            <div className="message-input-container">
              <textarea
                className="message-input"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type your message..."
              ></textarea>
              <button
                className="send-button"
                onClick={handleSendMessage}
                disabled={messagesLoading || !newMessage.trim()}
              >
                {messagesLoading ? "Sending..." : "Send"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OverviewChat;
