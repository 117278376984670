import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteEmployee, updateEmployee } from "../../apiService/apiService";
import "./EmployeeDetailInfoCard.css";
import { Employee, EmployeeData } from "../../constants/interfaces";

interface EmployeeDetailInfoCardProps {
  employee: Employee;
}

const EmployeeDetailInfoCard = ({ employee }: EmployeeDetailInfoCardProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    firstName: employee.firstName,
    lastName: employee.lastName,
    email: employee.email || "",
    phoneNumber: employee.phoneNumber || "",
    gender: employee.gender || "",
    dateOfBirth: employee.dateOfBirth
      ? new Date(employee.dateOfBirth).toISOString().split("T")[0]
      : "",
    hireDate: employee.hireDate
      ? new Date(employee.hireDate).toISOString().split("T")[0]
      : "",
    jobTitle: employee.jobTitle || "",
    paymentType: employee.paymentType,
    paymentAmount: employee.paymentAmount,
    status: employee.status || "",
  });
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (window.confirm("Are you sure you want to edit this employee?")) {
      setSubmitting(true);

      try {
        const updatedEmployeeData: EmployeeData = {
          firstName: employeeData.firstName,
          lastName: employeeData.lastName,
          paymentType: employeeData.paymentType,
          paymentAmount: employeeData.paymentAmount,
        };
        if (employeeData.email) {
          updatedEmployeeData.email = employeeData.email;
        }
        if (employeeData.phoneNumber) {
          updatedEmployeeData.phoneNumber = employeeData.phoneNumber;
        }
        if (employeeData.gender && employeeData.gender !== "Other") {
          updatedEmployeeData.gender = employeeData.gender;
        }
        if (employeeData.jobTitle) {
          updatedEmployeeData.jobTitle = employeeData.jobTitle;
        }
        if (employeeData.dateOfBirth) {
          updatedEmployeeData.dateOfBirth = new Date(
            employeeData.dateOfBirth,
          ).toISOString();
        }
        if (employeeData.hireDate) {
          updatedEmployeeData.hireDate = new Date(
            employeeData.hireDate,
          ).toISOString();
        }
        if (employeeData.status) {
          updatedEmployeeData.status = employeeData.status;
        }

        await updateEmployee(employee.employeeId, updatedEmployeeData);

        setModalOpen(false);
        navigate("/dashboard");
      } catch (error) {
        console.error("Failed to update employee:", error);
        alert("Error updating employee. Please try again.");
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this employee?")) {
      try {
        await deleteEmployee(employee.employeeId);
        navigate("/dashboard");
      } catch (error) {
        console.error("Failed to delete employee:", error);
        alert("Error deleting employee. Please try again.");
      }
    }
  };

  return (
    <div className="employee-detail-info-card">
      <div className="employee-card">
        <h2>{`${employee.firstName} ${employee.lastName}`}</h2>
        <div className="employee-info">
          <p>
            <strong>Employee ID:</strong> {employee.employeeId}
          </p>
          <p>
            <strong>Email:</strong> {employee.email || "N/A"}
          </p>
          <p>
            <strong>Phone:</strong> {employee.phoneNumber || "N/A"}
          </p>
          <p>
            <strong>Date of Birth:</strong>{" "}
            {employee.dateOfBirth
              ? new Date(employee.dateOfBirth).toLocaleDateString("en-CA")
              : "N/A"}
          </p>
          <p>
            <strong>Gender:</strong> {employee.gender || "N/A"}
          </p>
          <p>
            <strong>Hire Date:</strong>{" "}
            {employee.hireDate
              ? new Date(employee.hireDate).toLocaleDateString("en-CA")
              : "N/A"}
          </p>
          <p>
            <strong>Job Title:</strong> {employee.jobTitle || "N/A"}
          </p>
          <p>
            <strong>Payment Type:</strong> {employee.paymentType}
          </p>
          <p>
            <strong>Payment Amount:</strong> ${employee.paymentAmount}
          </p>
          <p>
            <strong>Status:</strong> {employee.status}
          </p>
        </div>
        <div className="employee-actions">
          <button className="edit-button" onClick={() => setModalOpen(true)}>
            Edit
          </button>
          <button className="delete-button" onClick={handleDelete}>
            Delete
          </button>
        </div>
      </div>

      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <span className="close-x" onClick={() => setModalOpen(false)}>
              <img src="/icons/x-icon.svg" alt="Close" />
            </span>
            <h2>Edit Employee</h2>
            <form onSubmit={handleSubmit}>
              <h3>Personal Information</h3>
              <div className="label-and-input-pair">
                <label>First Name:*</label>
                <input
                  type="text"
                  value={employeeData.firstName}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      firstName: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Last Name:*</label>
                <input
                  type="text"
                  value={employeeData.lastName}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      lastName: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Email:</label>
                <input
                  type="email"
                  value={employeeData.email}
                  onChange={(e) =>
                    setEmployeeData({ ...employeeData, email: e.target.value })
                  }
                />
              </div>
              <div className="label-and-input-pair">
                <label>Phone Number:</label>
                <input
                  type="text"
                  value={employeeData.phoneNumber}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      phoneNumber: e.target.value,
                    })
                  }
                />
              </div>
              <div className="label-and-input-pair">
                <label>Date of Birth:</label>
                <input
                  type="date"
                  value={employeeData.dateOfBirth}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      dateOfBirth: e.target.value,
                    })
                  }
                />
              </div>
              <div className="label-and-input-pair">
                <label>Gender:</label>
                <select
                  value={employeeData.gender}
                  onChange={(e) =>
                    setEmployeeData({ ...employeeData, gender: e.target.value })
                  }
                >
                  <option value="Other">Other</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <br />
              <h3>Employment Information</h3>
              <div className="label-and-input-pair">
                <label>Hire Date:</label>
                <input
                  type="date"
                  value={employeeData.hireDate}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      hireDate: e.target.value,
                    })
                  }
                />
              </div>
              <div className="label-and-input-pair">
                <label>Job Title:</label>
                <input
                  type="text"
                  value={employeeData.jobTitle}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      jobTitle: e.target.value,
                    })
                  }
                />
              </div>
              <div className="label-and-input-pair">
                <label>Payment Type:*</label>
                <select
                  value={employeeData.paymentType}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      paymentType: e.target.value,
                    })
                  }
                >
                  <option value="Salary">Salary</option>
                  <option value="Hourly">Hourly</option>
                </select>
              </div>
              <div className="label-and-input-pair">
                <label>Payment Amount:*</label>
                <input
                  type="number"
                  value={employeeData.paymentAmount}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      paymentAmount: +e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Status:</label>
                <select
                  value={employeeData.status}
                  onChange={(e) =>
                    setEmployeeData({ ...employeeData, status: e.target.value })
                  }
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                  <option value="On Leave">On Leave</option>
                </select>
              </div>
              <button type="submit" disabled={submitting}>
                {submitting ? "Editing..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeDetailInfoCard;
