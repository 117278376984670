import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../apiService/apiService";
import "./Login.css";
import { LoginData } from "../../constants/interfaces";

const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const loginData: LoginData = {
        email: email,
        password: password,
      };
      await loginUser(loginData);
      navigate("/dashboard");
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(err.message || "Login failed");
      } else {
        setError("Login failed");
      }
      console.error("Login Error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="login"
      style={{
        backgroundImage: "url(images/header/banner.png)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
    >
      <title>Login</title>
      <Link to="/">
        <img src="/images/logo/logo.svg" alt="" className="logo" />
      </Link>
      <div className="container">
        <header>Login</header>
        <form onSubmit={handleSubmit}>
          <div className="fields">
            <div className="input-field">
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-field">
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>
          <button className="nextBtn" type="submit" disabled={loading}>
            <span className="btnText">
              {loading ? "Logging in..." : "Login"}
            </span>
            <i className="uil uil-navigator" />
          </button>
          {error && <p className="error-message">{error}</p>}
        </form>
        <div className="signup-prompt">
          <p>
            Don&apos;t have an account? <a href="/register">Sign Up!</a>
          </p>
          <br></br>
          <p>
            <a href="/reset-password">Forgot password?</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
