import React, { useState } from "react";
import { UserLimitedInfo } from "../../constants/interfaces";
import {
  deleteUser,
  cancelSubscription,
  logoutUser,
} from "../../apiService/apiService";
import { useNavigate } from "react-router-dom";
import "./SettingsUserInfoCard.css";

interface SettingsUserInfoCardProps {
  user: UserLimitedInfo;
}

const SettingsUserInfoCard = ({ user }: SettingsUserInfoCardProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCancellingSubscription, setIsCancellingSubscription] =
    useState(false);
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      try {
        await cancelSubscription();
        alert("Subscription cancelled successfully.");
      } catch (error) {
        console.error("Error cancelling subscription:", error);
      }
      await deleteUser();
      navigate("/");
      alert("User deleted successfully.");
      logoutUser();
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("There was an error deleting the user.");
    }
  };

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription();
      alert("Subscription cancelled successfully.");
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      alert("There was an error cancelling the subscription");
    }
  };

  return (
    <div className="settings-user-info-card">
      <div className="user-card">
        <h2>{user.fullName}</h2>
        <div className="user-info">
          <p>
            <strong>Email:</strong> {user.email}
          </p>
          <p>
            <strong>Phone Number:</strong> {user.phoneNumber}
          </p>
          <p>
            <strong>Date of Birth:</strong>{" "}
            {new Date(user.dateOfBirth).toLocaleDateString()}
          </p>
          <p>
            <strong>Employee Limit:</strong> {user.employeeLimit}
          </p>
          <p>
            <strong
              style={{
                color: user.isSubscriptionCancelled ? "red" : "inherit",
              }}
            >
              {user.isSubscriptionCancelled
                ? "Subscription Expires on:"
                : "Subscription Renews on:"}
            </strong>{" "}
            {new Date(user.subscriptionValidUntil).toString()}
          </p>
        </div>

        <div className="delete-section">
          <button className="delete-button" onClick={() => setIsDeleting(true)}>
            Delete User
          </button>

          {isDeleting && (
            <div className="warning-section">
              <p className="warning-text">
                ⚠️ <strong>Warning!</strong> Deleting your account is permanent
                and cannot be undone.
              </p>
              <div className="confirmation-buttons">
                <button className="confirm-delete" onClick={handleDelete}>
                  Yes, Delete My Account
                </button>
                <button
                  className="cancel-delete"
                  onClick={() => setIsDeleting(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>

        {!user.isSubscriptionCancelled && (
          <div className="cancel-subscription-section">
            <button
              className="cancel-subscription-button"
              onClick={() => setIsCancellingSubscription(true)}
            >
              Cancel Subscription
            </button>

            {isCancellingSubscription && (
              <div className="warning-section">
                <p className="warning-text">
                  ⚠️ <strong>Warning!</strong> Your subscription will still be
                  valid until the end of the billing period. If you wish to
                  renew afterwards, you will need to resubscribe after the
                  subscription expires.
                </p>
                <div className="confirmation-buttons">
                  <button
                    className="confirm-delete"
                    onClick={handleCancelSubscription}
                  >
                    Yes, Cancel My Subscription
                  </button>
                  <button
                    className="cancel-cancel-subscription"
                    onClick={() => setIsCancellingSubscription(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsUserInfoCard;
